import React from "react";
// import {Container,Row,Form, Col, Button}  from 'react-bootstrap';
import {Container,Row,Col}  from 'react-bootstrap';
import '../../css/pages/profile.css';
import { withTranslation } from 'react-i18next';
import i18next from "i18next";

// import GaugeChart from 'react-gauge-chart';
import ProgressBar from "@ramonak/react-progress-bar";
import RadarChart from 'react-svg-radar-chart';

import ScoreBar from '../../component/bar/score';
import axios from 'axios';

class Profile extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0,0);

        this.state={
            reviewScore:725
        }
    }

    
    componentDidMount(){
        document.title = "nilaiQ | Profile";
    }

    async getData(event){
        event.preventDefault();
        let data = new FormData(event.target);

        let dataJSON = {};
        dataJSON.name = data.get("name");
        dataJSON.email = data.get("email");
        dataJSON.phone = data.get("phone");
        dataJSON.message = data.get("message");
        dataJSON.language = i18next.language;

        document.getElementById("header").classList.add("z-4");

        let url = "/service/sendEmail.php";

        axios.post(url, JSON.stringify(dataJSON), {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }
        }).then(res =>{
            let result = res.data;
        
            this.setState({
                resultForm : result
            })  
        });
    }

    closePop = () => {
        document.getElementById("header").classList.remove("z-4");
        this.setState({resultForm: null});
    }

    render(){
        // const { t,i18n } = this.props;
        
        return(
            <>
                <section className="bg-main-user">
                    <Container className="px-md-5 px-3 position-relative">
                        <Row>
                            <Col md="7">
                                <div className="bg-white br-2 p-5">
                                    <div className="color-main fw-600 fs-dev-2">Your nilaiQ Score Explained</div>
                                    <div className="py-5 px-5">
                                        <ScoreBar score={this.state.reviewScore} />

                                    {/* <GaugeChart id="gauge-chart2" 
                                      nrOfLevels={5} 
                                      colors={["#FF5F6D", "#FFC371"]} 
                                      cornerRadius = {0} 
                                      arcPadding={0} 
                                      percent={0.86} 
                                    /> */}
                                    </div>
                                    <div>
                                        photo
                                    </div>
                                    <div className="fw-600 color-main">
                                        Vincent
                                    </div>
                                    <div className="text-muted fs-min-1">
                                        Updated on 11 Nov 2021
                                    </div>
                                    <div className="pb-2 d-flex w-100 justify-content-center">
                                        <div className="bg-main br-2 px-3 py-1">
                                            Your nilaiQ score is : 1%
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="fw-600 color-main text-start">Score Classifications</div>
                                        <div className="d-flex pt-2">
                                            <div className="width-20">
                                                <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                    <div className="label-score px-3 br-2">Poor</div>
                                                </div>
                                                <div className="bar one br-left-2"></div>
                                            </div>
                                            <div className="width-20">
                                                <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                    <div className="label-score px-3 br-2">Secondary</div>
                                                </div>
                                                <div className="bar two"></div>
                                            </div>
                                            <div className="width-20">
                                                <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                    <div className="label-score px-3 br-2">Good</div>
                                                </div>
                                                <div className="bar three"></div>
                                            </div>
                                            <div className="width-20">
                                                <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                    <div className="label-score px-3 br-2">Great</div>
                                                </div>
                                                <div className="bar four"></div>
                                            </div>
                                            <div className="width-20">
                                                <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                    <div className="label-score bg-main px-3 br-2">Excellent</div>
                                                </div>
                                                <div className="bar five br-right-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pb-3">
                                        <div className="text-start color-main">
                                            <span className="pe-2 fw-600">90%</span>
                                            Information Completeness
                                        </div>
                                        <div>
                                            <ProgressBar completed={180} maxCompleted={200} customLabel="test" />
                                        </div>
                                    </div>
                                </div>
                                <div className="color-main px-3 pt-2">
                                    Log in to the nilaiQ mobile terminal, improve your personal information, improve your nilaiQ Score, and get more value-added services
                                </div>
                            </Col>

                            <Col md="5">
                                <div className="bg-white br-2 p-5">
                                    <div className="pb-3 position-relative">
                                        <div className="position-absolute w-100 h-100">
                                            <div className="top-0 d-flex w-100 justify-content-center fs-min-1">
                                                <div>
                                                    Profile Completeness
                                                    <img className="h-100" src={require('../../media/home/key/1.svg').default} alt="" />
                                                </div>
                                                
                                            </div>
                                            
                                            <div className="ml-min-2 top-dev-md-30-percent top-dev-25-percent d-flex width-more-20 justify-content-between position-absolute">
                                                <div className=" fs-min-2">
                                                    <div className="d-flex text-start align-items-center">
                                                        <div className="pe-2">
                                                            <div>Social</div><div>Networks</div>
                                                        </div>
                                                        <img className="h-100" src={require('../../media/home/key/2.svg').default} alt="" />
                                                    </div>
                                                </div>
                                                <div className=" fs-min-2">
                                                    <div className="d-flex text-start align-items-center">
                                                        <img className="h-100" src={require('../../media/home/key/3.svg').default} alt="" />
                                                        <div className="ps-2">
                                                            <div>Payment</div>
                                                            <div>Abilties</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="ms-3 bottom-0 d-flex width-80 justify-content-between position-absolute">
                                                <div className=" fs-min-1">
                                                    <div>
                                                        <img className="h-100" src={require('../../media/home/key/4.svg').default} alt="" />
                                                        <div>Behavioural Records</div>
                                                    </div>
                                                </div>
                                                <div className=" fs-min-1">
                                                    <div>
                                                        <img className="h-100" src={require('../../media/home/key/5.svg').default} alt="" />
                                                        <div>Reliability</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pt-5 pb-">
                                            <RadarChart className="pt-5"
                                                captions={{
                                                  // columns
                                                  battery: '',
                                                  design: '',
                                                  useful: '',
                                                  speed: '',
                                                  weight: ''
                                                }}
                                                data={[
                                                  // data
                                                  {
                                                    data: {
                                                      battery: 1,
                                                      design: 1,
                                                      useful: 1,
                                                      speed: 1,
                                                      weight: 1
                                                    },
                                                    meta: { color: '#4367AE' }
                                                  },
                                                  {
                                                    data: {
                                                      battery: 0.7,
                                                      design: .8,
                                                      useful: 0.9,
                                                      speed: 0.67,
                                                      weight: 0.8
                                                    },
                                                    meta: { color: 'red' }
                                                  }
                                                  
                                                ]}
                                                size={300}
                                            />  
                                        </div>
                                    </div>
                                    <div>
                                        <div className="d-flex py-2">
                                            <div className="col-2"><img className="h-100" src={require('../../media/home/key/1.svg').default} alt="" /></div>
                                            <div className="text-start col-8">Profile Completeness</div>
                                            <div className="col-2">290</div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="col-2"><img className="h-100" src={require('../../media/home/key/2.svg').default} alt="" /></div>
                                            <div className="text-start col-8">Social Networks</div>
                                            <div className="col-2">219</div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="col-2"><img className="h-100" src={require('../../media/home/key/4.svg').default} alt="" /></div>
                                            <div className="text-start col-8">Behavioural Records</div>
                                            <div className="col-2">10</div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="col-2"><img className="h-100" src={require('../../media/home/key/5.svg').default} alt="" /></div>
                                            <div className="text-start col-8">Reliability</div>
                                            <div className="col-2">0</div>
                                        </div>
                                        <div className="d-flex py-2">
                                            <div className="col-2"><img className="h-100" src={require('../../media/home/key/3.svg').default} alt="" /></div>
                                            <div className="text-start col-8">Payment Abilties</div>
                                            <div className="col-2">0</div>
                                        </div>
                                    </div>
                                </div>  
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default withTranslation()(Profile);