import React from "react";
import JobCareer from "../../component/job-card";
// import {Container,Row,Accordion,Col}  from 'react-bootstrap';
import {Container,Row,Col}  from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class Career extends React.Component {
    constructor(props) {
        super(props);
        window.scrollTo(0,0);
        this.state = {
            dataJob:{
                id:[
                    {
                        id: 1,
                        name:"Data Analytics Head",
                        category:"Data & Analytics",
                        url:""
                    },
                    {
                        id: 2,
                        name:"Mobile Application Lead",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 3,
                        name:"DevOps Engineer",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 4,
                        name:"Big Data Engineer",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 5,
                        name:"Data Governance",
                        category:"Data & Analytics",
                        url:""
                    },
                    {
                        id: 6,
                        name:"Cyber Security",
                        category:"Data & Analytics",
                        url:""
                    },
                    {
                        id: 7,
                        name:"Mobile Application Enginner",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 8,
                        name:"Test Engineer",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 9,
                        name:"Operations Manager",
                        category:"Operations",
                        url:""
                    },
                    {
                        id: 10,
                        name:"Digital Marketing / Branding",
                        category:"Marketing",
                        url:""
                    },
                    {
                        id: 11,
                        name:"Business Development",
                        category:"Operations",
                        url:""
                    },
                    {
                        id: 12,
                        name:"Finance Specialist",
                        category:"Marketing",
                        url:""
                    },
                    {
                        id: 13,
                        name:"Human Resource",
                        category:"Marketing",
                        url:""
                    },
                    {
                        id: 14,
                        name:"Legal & Compliance",
                        category:"Operations",
                        url:""
                    }
                ],
                en:[
                    {
                        id: 1,
                        name:"Data Analytics Head",
                        category:"Data & Analytics",
                        url:""
                    },
                    {
                        id: 2,
                        name:"Mobile Application Lead",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 3,
                        name:"DevOps Engineer",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 4,
                        name:"Big Data Engineer",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 5,
                        name:"Data Governance",
                        category:"Data & Analytics",
                        url:""
                    },
                    {
                        id: 6,
                        name:"Cyber Security",
                        category:"Data & Analytics",
                        url:""
                    },
                    {
                        id: 7,
                        name:"Mobile Application Enginner",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 8,
                        name:"Test Enginner",
                        category:"Engineering",
                        url:""
                    },
                    {
                        id: 9,
                        name:"Operations Manager",
                        category:"Operations",
                        url:""
                    },
                    {
                        id: 10,
                        name:"Digital Marketing / Branding",
                        category:"Marketing",
                        url:""
                    },
                    {
                        id: 11,
                        name:"Business Development",
                        category:"Operations",
                        url:""
                    },
                    {
                        id: 12,
                        name:"Finance Specialist",
                        category:"Marketing",
                        url:""
                    },
                    {
                        id: 13,
                        name:"Human Resource",
                        category:"Marketing",
                        url:""
                    },
                    {
                        id: 14,
                        name:"Legal & Compliance",
                        category:"Operations",
                        url:""
                    }
                ]
            }
        }
    }

    refreshData = (e) => {
        e.preventDefault();

        this.setState({
            
        })
    }

    componentDidMount(){
        document.title = "nilaiQ | Career";
    }

    openMore(){
        document.getElementById("btn-job").style.opacity = 0;
        
        this.setState({
            limit:this.state.dataJob.length
        });
            
    }

    seeMore(items,tipe){
        return(
            <>
                <div id={"btn-" + tipe} className="d-flex justify-content-center position-relative transition">
                    <div className="div-line position-absolute"></div>
                    <button className="z-1 br-1 bg-white border-0 p-2 fw-700 color-red" onClick={(e) => this.openMore(tipe)}>See More ({items - this.state.limit})</button>
                </div>
            </>
        )
    }

    activeSection(n){
        let btn = document.getElementsByClassName("btn-section");
        for(let i=0;i<btn.length;i++){
            btn[i].classList.remove("active");
        }
        btn[n].classList.add("active");
        this.slider.slickGoTo(n);
    }

    render(){

        // const settings = {
        //     dots: false,
        //     infinite: false,
        //     speed: 500,
        //     slidesToShow: 1,
        //     slidesToScroll: 0,
        //     draggable:false,
        //     arrows:false
        // };

        const { t,i18n } = this.props;

        let datajob = this.state.dataJob[i18n.language];
        let jobhtml = 
        <>
            <JobCareer items = {datajob}></JobCareer>
        </>;

        return(
            <>
                <section className="bg-main">
                    <Container>
                        <Row>
                            <div className="pt-md-0 pt-5 px-md-0 ph-2-3per4">
                                <div className="fs-dev-3 fw-700 pb-md-4 pb-3">{t('title.career')}</div>
                                <div className="pb-4">{t('career.info')}</div>
                            </div>
                        </Row>
                    </Container>
                </section>

                <section className="pt-md-5 pt-4 pb-3">
                    <Container>
                        <Row>
                            <div className="d-md-flex px-md-5 px-4">
                                <Col md="4" className="pb-md-0 pb-4">
                                    <div className="pt-3 text-dark fs-dev-2">Open Position</div>
                                </Col>
                                <Col md="8">
                                    {jobhtml}
                                </Col>
                            </div>
                        </Row>
                    </Container>
                </section>
                
            </>
            
        );
    }
}

export default withTranslation()(Career);