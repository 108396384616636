import React from 'react';
// import {Container,Row,Form, Col, Button}  from 'react-bootstrap';
import {Container,Row,Form,Button}  from 'react-bootstrap';
import Logo from "../../media/logo.png";

import VerifyImage from '../../component/slide-verify';

class Register extends React.Component{
    constructor(){
        super();
        window.scrollTo(0,0);

        this.openVerify = this.openVerify.bind(this);
        this.closeVerify = this.closeVerify.bind(this);

        this.state = {
            isVerify:false
        }
    }

    openVerify(){
        this.setState({
            isVerify:true
        })
    }

    closeVerify(){
        this.setState({
            isVerify:false
        })
    }

    render(){
        // let page ="phone"; // Phone, OTP, Password

        return (
        <>
            {
                (this.state.isVerify)?
                <div className='position-absolute top-0 w-100 h-100 bg-gray d-flex justify-content-center align-items-center z-4'>
                    <VerifyImage close={this.closeVerify} />
                </div>
                :
                <></>
            }
            
            <section className='bg-main-shadow'>
                <Container>
                    <Row>
                        <div>
                            <div>
                                <img className='img-logo' src={Logo} alt="" />
                            </div>
                            <div className='d-flex w-100 justify-content-center pt-4'>
                                <div className='p-4 bg-white br-1 width-md-75'>
                                    <div className='fw-700 fs-dev-2 text-dark'>What's your mobile number?</div>
                                    <div className='text-muted fs-min-1'>We'll send a code to your mobile number</div>
                                    
                                    <Form className='pt-4'>
                                        <Form.Group className="pb-3 position-relative d-flex align-items-center">
                                            <div className='position-absolute ps-2 py-2 text-dark'>+62</div>
                                            <Form.Control name="name" placeholder='Enter your mobile number' className="pe-3 ps-5 py-2 bg-main-shadow border-0" type="text" />
                                        </Form.Group>
                                    </Form>

                                    <div className='d-flex justify-content-between text-dark'>
                                        <div>View current KIT partners</div>
                                        <div>View</div>
                                    </div>

                                    <div className='pt-4'>
                                        <Button className='w-100' onClick={()=>{this.openVerify()}}>Continue</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                
            </section>
        </>
        )
    }
}

export default Register;