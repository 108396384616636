import React from "react";
import {Col}  from 'react-bootstrap';

import { Link } from "react-router-dom";

class NewsCard extends React.Component{

    render(){
        let dataColor = [
            {
                name:"Investment",
                red:225,
                green:220,
                blue:50
            },            
            {
                name:"Loan",
                red:100,
                green:220,
                blue:50
            },            
            {
                name:"Credit Card",
                red:200,
                green:150,
                blue:50
            },            
            {
                name:"KPR",
                red:200,
                green:100,
                blue:50
            },            
            {
                name:"Insurance",
                red:200,
                green:50,
                blue:50
            },            
            {
                name:"Credit Scoring",
                red:50,
                green:50,
                blue:220
            },            
            {
                name:"Press Release",
                red:50,
                green:100,
                blue:224
            },                
            {
                name:"AI",
                red:36,
                green:94,
                blue:224
            },
            {
                name:"Technology",
                red:129,
                green:19,
                blue:197
            }
        ];

        return (
            <>
                {
                    this.props.items.map( (musarrof) => (
                        <Col key={musarrof.id} md="4" className="py-md-4 px-md-3 py-3 px-0">
                            <div className="w-100 pb-3">
                                <img className="w-100 br-1" src={require(`../media/${musarrof.picUrl}`).default} alt=""/>
                            </div>
                            <div className="pb-3 d-flex w-100 flex-wrap">
                                {
                                    musarrof.category.map((line, index)=> (
                                        <div key={index} className="pe-3">
                                            {
                                            dataColor.map((item,index) => (
                                                line === item.name?
                                                    <div key={index} className="py-2 px-3 br-2" style={{backgroundColor:`rgb(${item.red},${item.green},${item.blue},0.1)`}}>
                                                        <div key={index} className="fw-700 fs-min-1" style={{color:`rgb(${item.red},${item.green},${item.blue},1)`}}>{line}</div>
                                                    </div>:null
                                        ))
                                            }
                                        </div>
                                    ))
                                }
                                
                            </div>
                            <Link to={`/news/${musarrof.id}`} className="cursor-pointer transition hover-main fw-500 fs-dev-1-half text-dark text-dev-left d-flex">
                                {musarrof.title}
                            </Link>
                            <div className="text-muted fs-min-2 text-dev-left">
                                {musarrof.date}
                            </div>
                        </Col>
                    ))
                }
            </>
        )

    };
}

export default NewsCard;