import React from "react";
import {Accordion}  from 'react-bootstrap';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBriefcase, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import '../css/component/job-card.css';

class JobCareer extends React.Component{

    render(){
        let listCategory = [];
        let listJob = [];

        let data = this.props.items;

        for(let i=0;i<data.length;i++){
            if(!listCategory.includes(data[i].category)){
                listCategory.push(data[i].category);
                listJob.push([]);
            }

            let index = listCategory.indexOf(data[i].category);
            listJob[index].push(data[i]);
        }
        return (
            <>
                <Accordion>
                    {
                        listCategory.map((line,index) =>
                            <Accordion.Item className="pb-3" eventKey={index}>
                                <Accordion.Header><div className="fs-dev-2">{line} ({listJob[index].length})</div></Accordion.Header>
                                <Accordion.Body>
                                    {
                                    listJob[index].map((item) =>
                                        <div className="p-3 d-flex justify-content-between w-100">
                                            <div className="col-6 text-dev-left">
                                                <div className="text-dark fs-dev-1">{item.name}</div>
                                                <div className="">Indonesia</div>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <div>
                                                    <button className="py-2 px-3 bg-main br-2 border-0 fs-dev-1">View & Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                </Accordion>
            </>
        )

    };
}

export default JobCareer;