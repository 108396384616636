import React, {Component} from 'react';
import './App.css';
import './css/public/developer.css';
import './css/public/responsive-theme.css';
import './css/public/theme.css';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Header from './component/header';
import Footer from './component/footer';
import {
    HashRouter as Router,
    withRouter,
    Redirect,
    Switch,
    Route
} from "react-router-dom";

// Page Guest
import Home from './pages/guest/home';
import Products from './pages/guest/products';
import Missing from './pages/guest/missing';
import Contact from './pages/guest/contact';
import News from './pages/guest/news';
import NewsDetail from './pages/guest/news-detail';
import Career from './pages/guest/career';
import Login from './pages/guest/login';
import Register from './pages/guest/register';
import Privacy from './pages/guest/privacy-policy';

// Page User
import Profile from './pages/user/profile';
import Profile2 from './pages/user/profile-2';

// import {useHistory} from "react-router-dom";
// import { browserHistory } from 'react-router';
class App extends Component{
    constructor(){
        super();

        this.state = {token: null}
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);

        let urlPageBlue = ["profile","login","profile-2","register"];
        let pageCustom = false;

        urlPageBlue.forEach((item)=>{
            if(document.URL.endsWith(item)){
                pageCustom = true;
            }
        })

        let footerHtml = pageCustom? <></>:<Footer/>;
        
        this.state = { footer: footerHtml };
    }
    
    componentDidMount() {

        // const { history } = this.props;

        // Decode entities in the URL
        // Sometimes a URL like #/foo#bar will be encoded as #/foo%23bar
        // window.location.hash = window.decodeURIComponent(window.location.hash);
        // const scrollToAnchor = () => {
        //     const hashParts = window.location.hash.split('#');
        //     if (hashParts.length > 2) {

                
        //         console.log("hashPart: "+hashParts);
        //         console.log("hashPart length: "+hashParts.length);
        //         // const hashPage = hashParts.slice(',')[2];

        //         // console.log("hashPage: "+hashPage);

        //         // if(hashPage === "nilaiq" || hashPage === "bespoke"){
        //         //     history.listen((action) => {
        //         //         if (action !== "PUSH") {
        //         //             this.setState({ token: null });
        //         //             window.location.href = "/#/";
        //         //         } 
        //         //     });                
        //         // }else{

        //             window.history.pushState("", document.title, "#" + window.location.hash.split('#')[1]);
        //             const hash = hashParts.slice(-1)[0];
        //             const yOffset = -100;
                    
        //             const element = document.querySelector(`#${hash}`);
        //             const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                    
        //             window.scrollTo({top: y, behavior: 'smooth'});
        //             // document.querySelector(`#${hash}`).scrollIntoView({block: 'start'});
    
        //         // }
                
        //     }
        // };
        // scrollToAnchor();
        // window.onhashchange = scrollToAnchor;

        // this.unlisten = this.props.history.listen((location, action) => {

        //     let urlPageBlue = ["profile","login","profile-2","register"];
        //     let pageCustom = false;

        //     urlPageBlue.forEach((item)=>{
        //         if(document.URL.endsWith(item)){
        //             pageCustom = true;
        //         }
        //     })

        //     let footerHtml = pageCustom? <></>:<Footer/>;
            
        //     this.setState({ footer: footerHtml });
        // });
    }

    testing(){
        console.log(this.state);
    }

    login(name){
        this.setState({ token: name });
        window.location.href = "/#/profile-2";
    }

    logout(){
        this.setState({ token: null });
        window.location.href = "/#/";
    }

    render(){
        // const { match, location, history } = this.props;
        // console.log(location);
        
    return (
        <div className="App position-absolute w-100 h-100 min-h-max">
            <Header token={this.state.token} logout={this.logout}/>
            <Router>
                <Switch>
                    {/* Guest Page */}
                    
                    <Route exact path='/home' component={Home}></Route>
                    <Route exact path='/' component={Home}>
                        <Redirect to="/home" />
                    </Route>
                    
                    <Route exact path='/404' component={Missing}></Route>
                    

                    <Route exact path='/faq' component={Home}></Route>
                    <Route exact path='/news' component={News}></Route>
                    <Route exact path='/news/:id' component={NewsDetail}></Route>
                    <Route exact path='/career' component={Career}></Route>
                    <Route exact path='/login' component={() => (<Login login={this.login} />)}></Route>
                    <Route exact path='/register' component={Register}></Route>

                    <Route exact path='/profile' component={Profile}></Route>
                    <Route exact path='/profile-2' component={Profile2}></Route>

                    <Route exact path='/team-member' component={Home}></Route>
                    <Route exact path='/application' component={Home}></Route>
                    <Route exact path='/loan-calculation' component={Home}></Route>
                    <Route exact path='/contact' component={Contact}></Route>

                    <Route exact path='/service' component={Home}></Route>
                    <Route exact path='/service/detail' component={Home}></Route>

                    <Route exact path='/portofolio' component={Home}></Route>
                    <Route exact path='/portofolio/detail' component={Home}></Route>

                    <Route exact path='/blog' component={Home}></Route>
                    <Route exact path='/blog/detail' component={Home}></Route>

                    <Route exact path='/products' component={Products}></Route>

                    <Route exact path='/privacy' component={Privacy}></Route>

                    {/* <Route path='/nilaiq' component={Products}>
                        <Redirect to="/products#nilaiq" />
                    </Route> */}
                    {/* <Route path='/bespoke' component={Products}>
                        <Redirect to="/products#bespoke" />
                    </Route> */}

                    {/* <Route exact path='/nilaiq' component={Products}></Route>
                    <Route exact path='/bespoke' component={Products}></Route> */}

                    {/* User Page */}

                    <Redirect to="/404" />

                </Switch>
            </Router>
            {this.state.footer}
        </div>
    );
    }
}

export default withRouter(App);