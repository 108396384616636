import React from "react";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class PopMessage extends React.Component{

    close = () => {
        this.props.onNameChange(null);
    }

    render(){
        document.getElementById("header").classList.add("z-4");
        
        let text = this.props.items;
        let iconAlert = <FontAwesomeIcon className="btn-main fw-700 fs-1" icon={faCheck} />;

        if(text.title === "Not Sent"){
            iconAlert = <FontAwesomeIcon className="btn-main fw-700 fs-1" icon={faInfoCircle} />;
        }
        
        return (
            <>
                <div className="position-fixed z-5 w-100 h-100 d-flex justify-content-center align-items-center top-0 bg-gray px-md-5 px-3">
                    <div className="p-md-5 p-3 position-relative bg-white br-1 col-md-9 col-12">
                        <div className="d-flex justify-content-center pb-5">
                            <div className="bg-button-gradient br-half p-4 d-flex">
                                {/* <FontAwesomeIcon className="fw-700 fs-1" icon={faCheck} /> */}
                                {iconAlert}
                            </div>
                        </div>
                        <div className="title-color fs-3 fw-700 pb-3">
                            {text.title}
                        </div>
                        <div className="title-color py-3">
                            {text.message}
                        </div>
                        <div className="d-flex justify-content-center w-100">
                            <button type="button" className="btn-main bg-button-gradient py-2 px-4 br-2 border-0 fw-700" onClick={()=>this.props.setChanged()}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )

    };
}

export default PopMessage;