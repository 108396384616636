import React from 'react';
import ScoreProvider from './score-provider';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Pic from '../../media/home/key.png';

const ScoreBar = (props) => {
  const { score } = props;

  // function for calculating the color
  // const calcColor = (percent, start, end) => {
  //       let a = (percent/end),
  //         b = (end - start) * a,
  //         c = b + start;

  //   // return an CSS hsl color string
  //   return 'hsl(' + c + ', 100%, 50%)';
  // };

  return (
    <ScoreProvider valueStart={0} valueEnd={score}>
      
      {(value) => (
        <div className='position-relative'>
          <div className='position-absolute w-100 h-100 d-flex align-items-center justify-content-center overflow-hidden p-3'>
            {/* <img className='w-100 h-100 br-half' src={Pic} alt="" /> */}
            <div className='w-100 h-100 br-half bg-white'></div>
          </div>
          <CircularProgressbar
            value={(value/750)*100}
            // text={`${value}`}
            text={<img src={Pic} alt="" />}
            circleRatio={0.7} /* Make the circle only 0.7 of the full diameter */
            styles={{
              trail: {
                // strokeLinecap: 'butt',
                transform: 'rotate(-126deg)',
                transformOrigin: 'center center',
                // border:'15px'
              },
              path: {
                // strokeLinecap: 'butt',
                transform: 'rotate(-126deg)',
                transformOrigin: 'center center',
                stroke: 'white',
                // border:'2rem'
              },
              text: {
                fill: '#4367AE',
                fontWeight: 700
              },
            }}
            strokeWidth={10}
          />
        </div>
        
      )}
    </ScoreProvider>
  );
};

export default ScoreBar;