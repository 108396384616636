import React from "react";
// import {Container,Row,Form, Col, Button}  from 'react-bootstrap';
import {Container,Row,Col}  from 'react-bootstrap';
import '../../css/pages/profile.css';
import { withTranslation } from 'react-i18next';
// import i18next from "i18next";

import GaugeChart from 'react-gauge-chart';
import ProgressBar from "@ramonak/react-progress-bar";
import RadarChart from 'react-svg-radar-chart';

import ScoreBar from '../../component/bar/score';
import axios from 'axios';

class Profile2 extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0,0);

        this.state={
            reviewScore:725
        }
    }

    
    componentDidMount(){
        document.title = "nilaiQ | Profile";
    }

    async getData(){
        let url = "https://h5test.nilaiq.com/credit_score/api/home/radar";

        axios.get(url).then(res=>{
            let result = res.data;
            console.log(result);
        })
    }

    closePop = () => {
        document.getElementById("header").classList.remove("z-4");
        this.setState({resultForm: null});
    }

    render(){
        // const { t,i18n } = this.props;
        const noSmoothing = points => {
            let d = 'M' + points[0][0].toFixed(4) + ',' + points[0][1].toFixed(4);
            for (let i = 1; i < points.length; i++) {
              d += 'L' + points[i][0].toFixed(4) + ',' + points[i][1].toFixed(4);
            }
            return d + 'z';
          };
        
        return(
            <>
                <section className="bg-main-user">
                    <Container className="px-md-5 px-3 position-relative">
                        <Row>
                            <Col md="9">
                                <Row>
                                    <Col md="5" className="color-main">
                                        <div className="pb-3">
                                            <div className="d-flex py-3 bg-white br-1">
                                               <div className="col-2"><img className="h-100" src={require('../../media/home/key/1.svg').default} alt="" /></div>
                                               <div className="text-start col-8">Profile Completeness</div>
                                               <div className="col-2 fw-600">290</div>
                                            </div>
                                        </div>  
                                        <div className="pb-3">
                                            <div className="d-flex py-3 bg-white br-1">
                                                <div className="col-2"><img className="h-100" src={require('../../media/home/key/2.svg').default} alt="" /></div>
                                                <div className="text-start col-8">Social Networks</div>
                                                <div className="col-2 fw-600">219</div>
                                            </div>
                                        </div>  
                                        <div className="pb-3">
                                            <div className="d-flex py-3 bg-white br-1">
                                                <div className="col-2"><img className="h-100" src={require('../../media/home/key/4.svg').default} alt="" /></div>
                                                <div className="text-start col-8">Behavioural Records</div>
                                                <div className="col-2 fw-600">10</div>
                                            </div>
                                        </div>  
                                        <div className="pb-3">
                                            <div className="d-flex py-3 bg-white br-1">
                                                <div className="col-2"><img className="h-100" src={require('../../media/home/key/5.svg').default} alt="" /></div>
                                                <div className="text-start col-8">Reliability</div>
                                                <div className="col-2 fw-600">0</div>
                                            </div>
                                        </div>  
                                        <div className="">
                                            <div className="d-flex py-3 bg-white br-1">
                                                <div className="col-2"><img className="h-100" src={require('../../media/home/key/3.svg').default} alt="" /></div>
                                                <div className="text-start col-8">Payment Abilties</div>
                                                <div className="col-2 fw-600">0</div>
                                            </div>
                                        </div>  
                                    </Col>
                                    <Col md="7" className=" pt-md-0 pt-3">

                                        <div className="pb-3 position-relative bg-white br-1">
                                            <div className="position-absolute w-100 h-100 d-flex justify-content-center">
                                                <div className="position-absolute top-2 d-flex w-100 justify-content-center fs-min-1">
                                                    <div>
                                                        <div>Profile Completeness</div>
                                                        {/* <img src={require('../../media/home/key/1.svg').default} alt="" /> */}
                                                    </div>

                                                </div>

                                                <div className="top-dev-md-35-percent top-dev-30-percent width-80 d-flex justify-content-between position-absolute">
                                                    <div className=" fs-min-2">
                                                        <div className="d-flex text-start align-items-center">
                                                            <div>
                                                                <div>Social</div><div>Networks</div>
                                                            </div>
                                                            {/* <img className="h-100" src={require('../../media/home/key/2.svg').default} alt="" /> */}
                                                        </div>
                                                    </div>
                                                    <div className=" fs-min-2">
                                                        <div className="d-flex text-start align-items-center">
                                                            {/* <img className="h-100" src={require('../../media/home/key/3.svg').default} alt="" /> */}
                                                            <div>
                                                                <div>Payment</div>
                                                                <div>Abilties</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ml-min-2 bottom-2 d-flex width-60 justify-content-between position-absolute">
                                                    <div className=" fs-min-1">
                                                        <div>
                                                            {/* <img className="h-100" src={require('../../media/home/key/4.svg').default} alt="" /> */}
                                                            <div>Behavioural Records</div>
                                                        </div>
                                                    </div>
                                                    <div className=" fs-min-1">
                                                        <div>
                                                            {/* <img className="h-100" src={require('../../media/home/key/5.svg').default} alt="" /> */}
                                                            <div>Reliability</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-5">
                                                <RadarChart className="pt-5"
                                                    options={{
                                                        scales: 0,
                                                        smoothing : noSmoothing
                                                    }}
                                                    captions={{
                                                      // columns
                                                      battery: '',
                                                      design: '',
                                                      useful: '',
                                                      speed: '',
                                                      weight: ''
                                                    }}
                                                    data={[
                                                      // data
                                                      {
                                                        data: {
                                                          battery: 0.7,
                                                          design: .8,
                                                          useful: 0.9,
                                                          speed: 0.67,
                                                          weight: 0.8
                                                        },
                                                        meta: { color: '#CDE1FB' }
                                                      },
                                                      {
                                                        data: {
                                                          battery: 1,
                                                          design: 1,
                                                          useful: 1,
                                                          speed: 1,
                                                          weight: 1
                                                        },
                                                        meta: { color: 'transparent' }
                                                      }
                                                    ]}
                                                    size={315}
                                                />  
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <Row className="pt-4">
                                    <Col>
                                        <div className="bg-white br-1 py-3 px-4">
                                            <div className="color-main fw-700 text-start fs-dev-2 pb-3">Score Classifications</div>
                                            <div className="pb-3">
                                                <div className="d-flex pt-2">
                                                    <div className="width-20">
                                                        <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                            <div className="label-score px-3 br-2">Poor</div>
                                                        </div>
                                                        <div className="bar one br-left-2"></div>
                                                    </div>
                                                    <div className="width-20">
                                                        <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                            <div className="label-score px-3 br-2">Secondary</div>
                                                        </div>
                                                        <div className="bar two"></div>
                                                    </div>
                                                    <div className="width-20">
                                                        <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                            <div className="label-score px-3 br-2">Good</div>
                                                        </div>
                                                        <div className="bar three"></div>
                                                    </div>
                                                    <div className="width-20">
                                                        <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                            <div className="label-score px-3 br-2">Great</div>
                                                        </div>
                                                        <div className="bar four"></div>
                                                    </div>
                                                    <div className="width-20">
                                                        <div className="fs-min-1 pb-1 d-flex w-100 justify-content-center">
                                                            <div className="label-score bg-main px-3 br-2">Excellent</div>
                                                        </div>
                                                        <div className="bar five br-right-2"></div>
                                                    </div>
                                                </div>

                                                <div className="d-flex w-100 position-relative ms-3 pb-4">
                                                    <div className="position-absolute ml-min-2">350</div>
                                                    <div className="width-20 text-end">500</div>
                                                    <div className="width-20 text-end">600</div>
                                                    <div className="width-20 text-end">650</div>
                                                    <div className="width-20 text-end">700</div>
                                                    <div className="width-20 text-end">950</div>
                                                </div>

                                                <div>
                                                    <div className="text-start color-main">
                                                        <span className="pe-2 fw-600">90%</span>
                                                        Information Completeness
                                                    </div>
                                                    <div>
                                                        <ProgressBar completed={180} maxCompleted={200} customLabel=" " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md="3" className="position-relative pt-md-0 pt-3">
                                <div className="bg-main br-1 h-100 pt-3 align-items-center d-flex flex-wrap">
                                    <div className="col-12 px-0">
                                        <div className="pb-4 px-5">
                                            <ScoreBar score={this.state.reviewScore} />
                                        </div>
                                        <div className="fw-600">Vincent</div>
                                        <div className="fs-min-1">Updated on 11 Nov 2021</div>
                                        <div className="d-flex justify-content-center w-100 pt-4">
                                            <div className="py-2 px-5 br-2 bg-white color-main">Detail</div>
                                        </div>
                                    </div>

                                    <div className="col-12 px-0">
                                        <div className="position-relative pb-3 d-flex justify-content-center">
                                            <div className="position-absolute bottom-0 left-1">Low</div>
                                            <div className="position-absolute bottom-0 right-1">High</div>
                                            <GaugeChart id="gauge-chart4" className="z-2"
                                                arcPadding={0} 
                                                cornerRadius={0} 
                                                // background: linear-gradient(253.34deg, #8DE7EF 15.28%, #FAC88A 81.67%);
                                                // background: linear-gradient(253.34deg, #8DE7EF 15.28%, #FAC88A 81.67%)
                                                colors={["#FAC88A","#8DE7EF"]} 
                                                percent={0.6} 
                                                hideText={true}
                                                needleColor={'#E89160'}
                                                needleBaseColor={'#E89160'}
                                            />
                                        </div>
                                        <div className="fw-600 fs-dev-2">725</div>
                                        <div>Your nilaiQ Score is</div>
                                        <div className="fw-600 fs-dev-2">Excelent!</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default withTranslation()(Profile2);