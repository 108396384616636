import React from 'react';
import {Container,Row,Form, Col, Button}  from 'react-bootstrap';
import Logo from "../../media/logo.png";

import { Link } from "react-router-dom";

class Login extends React.Component{
    constructor(){
        super();
        window.scrollTo(0,0);
        this.state = {
            tabLogin : "password"
        }
    }

    changeTab(type){
        this.setState({tabLogin:type});
    }

    login(name){
        this.props.login(name);
    }

    render(){
        // let test = this.props.login;
        return (
        <>
            <div className='position-absolute top-0 w-100 h-100 bg-gray d-none justify-content-center align-items-center z-4'>
                
            </div>
            <section className='bg-main-shadow'>
                <Container>
                    <Row>
                        <div>
                            <div>
                                <img className='img-logo' src={Logo} alt="" />
                            </div>
                            <div className='d-flex w-100 justify-content-center pt-4'>
                                <div className='p-4 bg-white br-1 width-md-60'>
                                    {
                                        (this.state.tabLogin === "password")?
                                        <>
                                            <div className='d-flex justify-content-between'>
                                                <div className='fw-700 fs-dev-2 text-dark pe-5'>Login with Password</div>
                                                <div className='cursor-pointer hover-main fs-min-1' onClick={() => this.changeTab("sms")}>Login with SMS</div>
                                            </div>

                                            <Form className='pt-4'>
                                                <Form.Group className="pb-3 position-relative d-flex align-items-center">
                                                    <div className='position-absolute ps-2 py-2 text-dark'>+62</div>
                                                    <Form.Control name="name" placeholder='Enter your mobile number' className="pe-3 ps-5 py-2 bg-main-shadow border-0" type="text" />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Control name="password" placeholder='Password' className="px-3 py-2 bg-main-shadow border-0" type="password" />
                                                </Form.Group>
                                                <div className='d-flex justify-content-end w-100'>
                                                    <Link to={"/forgot-password/"} className="cursor-pointer pt-2 hover-main fs-min-1">
                                                        Forgot Password
                                                    </Link>
                                                </div>
                                            </Form>

                                            <div className='d-flex justify-content-between text-dark py-4'>
                                                <div>View current KIT partners</div>
                                                <div>View</div>
                                            </div>

                                            <div className='pb-4'>
                                                <Button className='w-100 bg-main' onClick={()=> this.login("vincent")}>Sign In</Button>
                                            </div>
                                        </>
                                        :(this.state.tabLogin === "sms")?
                                        <>
                                            <div className='d-flex justify-content-between'>
                                                <div className='fw-700 fs-dev-2 text-dark pe-5'>Login with SMS</div>
                                                <div className='cursor-pointer hover-main fs-min-1' onClick={() => this.changeTab("password")}>Login with Password</div>
                                            </div>
                                            
                                            <Form className='pt-4'>
                                                <Form.Group className="pb-3 position-relative d-flex align-items-center">
                                                    <div className='position-absolute ps-2 py-2 text-dark'>+62</div>
                                                    <Form.Control name="name" placeholder='Enter your mobile number' className="pe-3 ps-5 py-2 bg-main-shadow border-0" type="text" />
                                                </Form.Group>
                                                <Form.Group className='position-relative d-flex align-items-center'>
                                                    <div className='position-absolute pe-2 py-2 text-dark hover-main cursor-pointer right-0'>Kirim melalui SMS</div>
                                                    <Form.Control name="password" placeholder='Enter 6-digit SMS verification code' className="px-3 py-2 bg-main-shadow border-0" type="password" />
                                                </Form.Group>
                                                
                                            </Form>
                                            
                                            <div className='d-flex justify-content-between text-dark py-4'>
                                                <div>View current KIT partners</div>
                                                <div>View</div>
                                            </div>
                                            
                                            <div className='pb-4'>
                                                <Button className='w-100 bg-main'>Sign In</Button>
                                            </div>
                                        </>
                                        :
                                        <></>
                                    }
                                    
                                    <div>
                                        
                                        <Link to={"/register"} className="btn border-black-1 py-3 w-100 bg-white color-main cursor-pointer">
                                            Sign Up
                                        </Link>
                                        {/* <Button className='w-100 bg-white color-main'>Sign Up</Button> */}
                                    </div>

                                    <div className='py-3 position-relative d-flex align-items-center justify-content-center'>
                                        <div className='position-absolute border-black-1 w-100 z-1'></div>
                                        <div className='text-uppercase z-2 bg-white px-3'>Or sign in with</div>
                                    </div>

                                    <div className='d-flex text-dark'>
                                        <Col md="4" className='px-3'>
                                            <div className='border-black-2 br-1 py-2'>Google</div>
                                        </Col>
                                        <Col md="4" className='px-3'>
                                            <div className='border-black-2 br-1 py-2'>Facebook</div>
                                        </Col>
                                        <Col md="4" className='px-3'>
                                            <div className='border-black-2 br-1 py-2'>Apple</div>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
                
            </section>
        </>
        )
    }
}

export default Login;