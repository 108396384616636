import React from 'react'
import ReactMarkdown from 'react-markdown'
import { withTranslation } from 'react-i18next';
import {Container}  from 'react-bootstrap';

class Privacy extends React.Component {

    componentDidMount(){
        document.title = "nilaiQ | Privacy Policy";
    }    

    render(){
        const { t } = this.props;
        return(

            <>
                <section className="text-dark text-dev-left position-relative">
                    <Container>
                    <div className="fs-1 w-700 text-dark text-center py-5">{t('menu.privacy')}</div>

                    <ReactMarkdown                                                                                                  
                    >{`

This Privacy Policy explains what information of yours will be collected by nilaiQ when you access services provided by nilaiQ on which a link to this Privacy Policy is displayed, and all other communications with you through written or oral means, such as full name, email or phone. It also describes how the information will be used, how you can control the collection, correction and/or deletion of information and how to get in touch with us if you need. Our privacy practices are designed to provide a high level of protection for your personal data. We will not use or share your information with anyone except as described in this Privacy Policy. This Policy is incorporated into, and is subject to, the Terms and Conditions of use available on this service. Please read this Privacy Policy carefully to understand our views and practices regarding your personal data before browsing this service.

Please get familiarized with [nilaiQ Personal Data Protection Rules](https://www.nilaiq.com/#/privacy)

**VERSION**

This version of Privacy Policy was published on 29/09/2023



* **HOW THE INFORMATION ABOUT YOU IS USED**

We use the information that we collect to operate and maintain our services and to provide the services and information that you request, to allow you contact us, provide access to requested more information, respond to your comments, questions and concerns and for other purposes specified herein.

If you have contacted us via this service or otherwise, we may process your personal data you provided to us to be able to answer your questions, to organize the meeting, call or other communication based on our legitimate interest. 

We will send you updates on latest products, event announcements, thought leadership or notifications of company news, and marketing offers if you tick the box to receive news and updates from us. We are relying on your consent which you provide by ticking that box. If you have consented to receive marketing, you have a right to withdraw your consent to marketing communication at any time. You may unsubscribe or update your email preferences at any time by following the instructions contained within the email or by sending an email to the address provided in the [Contact Us](https://www.nilaiq.com/#/contact) section. You may manage your subscriptions and you may withdraw your consent to marketing communication at any time.

We can also process your personal data if it is necessary for us to administer the contractual relationship between ourselves and our suppliers/clients in connection with the performance of a contract. If you are a representative of our current/potential client, vendor, partner we may process your personal data to develop and/or maintain business relations and communications, to promote our new products, works and services, to invite you to the meetings, events and organize them based on our legitimate interest.

Where you are our existing client and we have an existing relationship with you, or we reasonably believe you can be our client, we will send you information about products and services, events or other material that will be relevant to your individual interests and process your personal information to make sure we send you only relevant, targeted information based on our legitimate interest. You can opt-out of this at any time by following the instructions contained within the email or by sending an email to the address provided in the [Contact Us](https://www.nilaiq.com/#/contact) section.

If you have visited or intend to visit our event or participate in our conference, we may process your personal data to invite you and to organize your participation in our existing and future events/conferences based on our legitimate interest. We may also send you marketing emails where you give us your email address expressly to receive marketing.



* **WHAT INFORMATION DO WE PROCESS**

We collect information about you when you submit the information through the form on the [Contact Us](https://www.nilaiq.com/#/contact) page or otherwise make the information available for us (by full name, phone, email, etc.) or our partners or vendors, as well as from publicly available sources. For example, we collect your full name, email, phone number and additional information you provide us when you request contacting you directly.

We may also collect, store and use any other personal data that you choose to send to us. In cases where it is specifically stated that some data is not mandatory, you are free not to submit this data. Our services are not directed to children and we do not knowingly collect personal data from children. If you are under 16 years of age (or any other age under the applicable law), then please do not use or access the services at any time or in any manner or contact us and provide the consent of your parent or legal guardian



* **COOKIES**

We collect anonymous information from visits to our services to help us provide better customer service. For example, we record certain automatically collected information, which include IP address, web browser, OS, the web pages or sites visited just before or just after using the services, keep track of the domains from which you visit and we also measure your activity on the services, but we do so in ways that keep the information anonymous. nilaiQ uses this data to analyze trends and statistics, to prepare analytics for business intelligence, to target our advertising and to help us provide better customer service. Cookies can be used to provide you with tailored information from a service. You can determine if and how a cookie will be accepted by configuring your browser, which is installed in the computer you are using to access the services. If you desire, you can change those configurations in your browser to accept all cookies, to be notified when a cookie is sent, or to reject all cookies.



* **YOUR RIGHTS**

If you wish to access or amend any personal data we hold about you, or to request that we delete any information about you may contact us as set forth in the [Contact Us](https://www.nilaiq.com/#/contact) section. When technically feasible, nilaiQ will transfer your personal data to you or transmit it directly to another controller. At any time, you may also object to processing of your personal data for direct marketing purposes or ask us to restrict processing of your personal data. If you believe your right to privacy granted by applicable data protection laws has been infringed upon, please contact us as set forth in the [Contact Us](https://www.nilaiq.com/#/contact) section. You also have a right to lodge a complaint with data protection authorities.



* **DATA PROCESSING AND TRANSFER**

In addition to nilaiQ, in some cases, personal data may be accessible to certain types of persons in charge, involved with the operation of the services or external parties). These third parties may have access to, or process personal data as part of providing those services for us. Your information collected by nilaiQ may be stored and processed in Indonesia. 

nilaiQ may transfer information that we collect about you, including personal data, to affiliated entities, or to other third parties. We will comply with UU-Perlindungan Data Pribadi (PDP) requirements providing adequate protection for the transfer of personal data, in particular, will enter into the standard contractual clauses approved by the PDP laws. By submitting your personal data, you agree to this transfer, storing and processing.



* **INTEGRATED SERVICES**

You may be given the option to fill in the [Contact Us](https://www.nilaiq.com/#/contact) form, or to log in to our site through the use of your user name and passwords for certain services provided by third parties (each, an “Integrated Service”), or otherwise have the option to authorize an Integrated Service to provide personal data or other information to us. By authorizing us to connect with an Integrated Service, you authorize us to access and store your full name, email address(es), date of birth and other information that the Integrated Service makes available to us, and to use and disclose it in accordance with this Privacy Policy. You should check your privacy settings on each Integrated Service to understand what information that Integrated Service makes available to us, and make changes as appropriate. Please review each Integrated Service’s terms of use and privacy policies carefully before using their services and connecting us.



* **DATA RETENTION**

We keep your personal data for as long as it is necessary to do so to fulfil the purposes for which it was collected as described above. The criteria we use to determine data retention periods for personal data includes the following: retention in accordance with legal and regulatory requirements. We may retain and use your information in order to comply with our legal obligations, resolve disputes, and prevent abuse.



* **SECURITY**

We endeavour to use appropriate technical and physical security measures to protect your personal data which is transmitted, stored or otherwise processed by us, from accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access.



* **CHANGES TO OUR PRIVACY POLICY**

We reserve the right to change this Privacy Policy at any time at our sole discretion. If we make changes to this Privacy Policy they will be posted on the services so that you are always aware of what information we gather, how we might use that information and in what circumstances we may disclose it.



* **CONTACTING US**

In the event you have any questions, comments and requests about the information set out in this Privacy Policy, please contact us by submitting the [Contact Us](https://www.nilaiq.com/#/contact) form on our website.
                    `}</ReactMarkdown>                  

                    </Container>                    
                </section>                        


            </>
    
    
        );



    }

}

export default withTranslation()(Privacy);
