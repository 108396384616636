import React from "react";
import {Container,Row,Col}  from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';

// import PicBenefit2 from '../../media/home/benefit-coor.webp';
import PicCoor1 from '../../media/home/partner/bank-sinarmas.webp';
import PicCoor2 from '../../media/home/partner/asuransi-simas-jiwa.webp';
import PicCoor3 from '../../media/home/partner/narindo.webp';
import PicCoor4 from '../../media/home/partner/asuransi-sinarmas.webp';
import PicCoor5 from '../../media/home/partner/sinarmas-multifinance.webp';
import PicCoor6 from '../../media/home/partner/danamas.webp';
// import PicCoor7 from '../../media/home/partner/Telecom.webp';
// import PicCoor8 from '../../media/home/partner/Others.webp';

// import RadarChart from 'react-svg-radar-chart';
import 'react-svg-radar-chart/build/css/index.css';

import '../../css/pages/home.css';


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

// import Slider from "react-slick";
// import Player from '../../component/player';
import i18next from "i18next";

class Home extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0,0);
    
        this.state = {
            linkVideo: null
        }

        // this.setState({
        //     linkVideo: null
        // })
    }

    closeVideo = linkVideo => {
        document.getElementById("header").classList.remove("z-4");
        this.setState({ linkVideo })
    }
    
    openVideo(name){
        this.setState({
            linkVideo: name
        })
    }
    
    componentDidMount(){
        document.title = "nilaiQ | Home";
    }

    changeTab(index,type){
        let doc;
        switch(type){
            case 'benefit':
                doc = document.getElementsByClassName("tab-benefit");
                this.benefitSlider.slickGoTo(index);
                break;
            case 'cooporating':
                doc = document.getElementsByClassName("tab-partner");
                this.coorporatingSlider.slickGoTo(index);
                break;
            default:
                doc = document.getElementsByClassName("tab-benefit");
                this.benefitSlider.slickGoTo(index);
                break;
        }

        for(let i=0;i<doc.length;i++){
            doc[i].classList.remove("active");
        }
        doc[index].classList.add("active");
    }

    data = [
        {
            data: {
                profile: 0.45,
                payment: 0.55,
                reliability: 0.65,
                behaviour: 0.42,
                social: 0.65
            },
            meta: { color: 'rgba(0,0,0,0)' }
        },
        {
            data: {
                profile: 0.75,
                payment: 0.75,
                reliability: 0.75,
                behaviour: 0.75,
                social: 0.75
            },
            meta: { color:  'rgba(0,0,0,0)' }
          }
    ];
  
    captions = {
        profile: 'Profile Completeness',
        payment: 'Payment Abilities',
        reliability: 'Reliability',
        behaviour: 'Behavioural Records',
        social: 'Social Networks'
    };

    optionsChart = {
        captionMargin: 80,
        captionProps: () => ({
          className: 'caption',
          textAnchor: 'middle',
          fontSize: 20,
          fontFamily: 'sans-serif',
          textColor: "red"
        })
    }

    render(){
        
        // const settings = {
        //     dots: false,
        //     infinite: true,
        //     slidesToShow: 1,
        //     slidesToScroll: 0,
        //     swipe: false
        // };

        // const { t,i18n } = this.props;
        const { t } = this.props;
        
        // let playerHtml = <Player items= {this.state.linkVideo} setChanged={this.closeVideo}></Player>;

        // let benefitCustomer = t('home.benefit-customer.detail', {joinArrays : '\n'}).split('\n').map(line => <li className="text-dev-left" key="{line}">{line}</li>)
        return(
            <>
                {
                  <div></div>
                }

                <Container className="position-relative z-2">
                    <Row className="d-block">
                        {/* Section Headline */}
                        <section className="">
                            <div className="bg-img-headline pt-cr-5 md-pt-1">
                                <Container className="justify-content-center pt-cr-3 md-pt-1">
                                    <div className="fs-dev-3 lh-sm fw-600 pb-md-4 pb-3 pt-cr-3 md-pt-1 ls-1">{t('home.headline')}</div>
                                </Container>
                                <Container className="justify-content-center ">
                                    <div className="pb-md-4 pb-3 fs-min-1 fw-400">{t('home.sub-headline')}</div>
                                </Container>
                                {/* <div className="fs-dev-3 lh-sm fw-600 pb-md-4 pb-3 pt-5">{t('home.headline')}</div> */}
                                <div className="">
                                    {/* <div className="pb-3 fs-min-1 fw-400">{t('home.sub-headline')}</div> */}
                                    <Row className="justify-content-md-center">
                                        <Col md="auto pt-2">
                                            <Link className="p-0 btn-wrap-text"   to={{
                                                pathname: "/products",
                                                state: { colorText: "blue" }
                                            }}>
                                                <button className="btn-main bg-button-gradient w-100 br-2 py-2 px-4 border-0">{t('home.headline-learn')}</button>
                                            </Link>

                                            {/* <a className="p-0 btn-wrap-text" href="/#/products">
                                                <button className="btn-main bg-button-gradient w-100 br-2 py-2 px-4 border-0">{t('home.headline-learn')}</button>
                                            </a> */}
                                        </Col>
                                        <Col md="auto pt-2">
                                            <a className="p-0 btn-wrap-text" href="/#/contact">
                                                <button type="button" className="w-100 br-2 py-2 px-4 border-0 border-main d-flex bg-white color-main justify-content-center align-items-center"> 
                                                    <span className="gradient-text">
                                                        {t('home.headline-demo')}
                                                    </span>
                                                </button>
                                            </a>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={require('../../media/bg/img-headline.png').default} alt="" />
                                </div>                                                                 */}
                            </div>
                        </section>
                        {/* Section Why */}
                        <section className="px-6">
                            <div id="partner" className="fs-dev-md-3 fs-dev-3 fw-600 title-color lh-sm">{t('title.why')}</div>
                            {/* <h4 className="fs-dev-3 text-dev-md-left fw-600">{t('title.why')}</h4> */}
                            <div className="d-md-flex text-dev-md-left pt-md-5 pt-3 pb-4 fw-400 title-color">
                                <Col md="6">
                                    {/* <p className=" text-dev-md-left lh-34">{t('home.intro')}</p> */}
                                    <ul className="mb-0 no-bullets">
                                        {t('home.whySection', { returnObjects: true }).map((item, index) => (
                                            <li className="text-dev-md-left text-justify fs-dev-1-half lh-md title-color px-4" key={index}>
                                                {item.title}
                                                <p className="text-dev-md-left fs-dev-1 fc-gray lh-sm pb-2 pt-2">{item.subTitle}</p>
                                            </li>
                                        ))}                                        
                                    </ul>
                                </Col>
                                <Col md="6" className="px-4 pt-md-0 pt-3">
                                    {
                                        (i18next.language ==='en')?
                                        <img className="w-100 image-div" src={require('../../media/home/why-img.webp').default} alt="" loading="lazy" width="576" height="380"/>
                                        :
                                        (i18next.language ==='id')?
                                        <img className="w-100 image-div" src={require('../../media/home/why-img.webp').default} alt="" loading="lazy"/>
                                        :
                                        null
                                    }
                                </Col>
                            </div>
                        </section>

                        {/* Section Products */}
                        <section className="px-6 pt-5">
                            <div className="bg-bluelight pt-5">
                                <h4 id="solution" className="fs-dev-3 fw-600 title-color">{t('title.solution')}</h4>
                                <div className="pb-5 fs-dev-1 lh-sm px-4 fw-400">{t('title.sub-solution')}</div>
                                <div id="nilaiq" className="d-md-flex w-100 pb-5">
                                    <Col md="6" className="px-4 pt-md-0 py-3">
                                    {
                                        (i18next.language ==='en')?
                                        <img className="w-100 image-div" src={require('../../media/home/nilaiq-score.webp').default} alt="" loading="lazy" width="576" height="380"/>
                                        :
                                        (i18next.language ==='id')?
                                        <img className="w-100" src={require('../../media/home/nilaiq-score.webp').default} alt="" loading="lazy" width="576" height="380"/>
                                        :
                                        null
                                    }
                                    </Col>
                                    <Col md="6" className="ps-md-5 px-4">
                                        <h4 className="fw-600 fs-dev-2-big text-dev-md-left pb-3 title-color mb-0">{t('title.nilaiq')}</h4>
                                        <p className="text-dev-md-left fs-dev-1-half fw-400 title-color">{t('home.nilaiqSection')}</p>
                                        <p className="text-dev-md-left fs-dev-1 fc-gray lh-sm">{t('home.subNilaiqSection')}</p>
                                        <div className="btn-wrap-text text-dev-md-left w-100">
                                            <HashLink to="/products#nilaiq">
                                                <button type="button" className="bg-button-gradient br-2 py-1 px-4 border-0 btn-main btn-wrap-text">
                                                    {t('button.see-details')}
                                                </button>
                                            </HashLink>                                                                                         
                                            {/* <Nav.Link className="btn-wrap-text" activeclassname="fw-500" style={{color: `${this.state.colorText}`}} href="/#/nilaiq">
                                                <button type="button" className="bg-button-gradient br-2 py-1 px-4 border-0 btn-main btn-wrap-text" onClick={()=> this.openVideo("How-it-works")}>
                                                    {t('button.see-details')}
                                                </button>
                                            </Nav.Link> */}
                                        </div>
                                    </Col>
                                </div>
                                <div id="bespoke" className="d-md-flex w-100 pt-5 pb-5">
                                    <Col md="6" className="px-4">
                                        <h4 className="fw-600 fs-dev-2-big text-dev-md-left pb-3 title-color">{t('title.bespokeScores')}</h4>
                                        <p className="fs-dev-1-half text-dev-md-left fw-400 title-color">{t('home.bespokeSection')}</p>
                                        <p className="text-dev-md-left fs-dev-1 fc-gray lh-sm">{t('home.subBespokeSection')}</p>
                                        <div className="w-100 text-dev-md-left">
                                            {/* <Nav.Link href={"/#/bespoke"} className="btn-wrap-text">
                                                <button type="button" className="bg-button-gradient d-flex w-100 justify-content-center align-items-center br-2 py-1 px-4 border-0 btn-main" onClick={()=> this.openVideo("How-it-works")}>
                                                    {t('button.see-details')}
                                                </button>
                                            </Nav.Link> */}
                                            <HashLink to="/products#bespoke">
                                                <button type="button" className="bg-button-gradient br-2 py-1 px-4 border-0 btn-main btn-wrap-text">
                                                    {t('button.see-details')}
                                                </button>
                                            </HashLink>                                            
                                        </div>
                                    </Col>

                                    <Col md="6" className="pt-md-0 py-3 px-4">
                                    {
                                        (i18next.language ==='en')?
                                        <img className="w-100 image-div" src={require('../../media/home/bespoke-score.webp').default} alt="" loading="lazy" width="576" height="380"/>
                                        :
                                        (i18next.language ==='id')?
                                        <img className="w-100" src={require('../../media/home/bespoke-score.webp').default} alt="" loading="lazy"/>
                                        :
                                        null
                                    }
                                    </Col>
                                </div>                                                     
                            </div>

                        </section>

                        {/* Section Question */}
                        <section className="br-1">
                            <Container className="px-5 bg-color-gradient br-1 text-vertical-middle">
                                <Row className="w-100 m-0 pt-3">
                                    <Col md="9" className="text-dev-left text-horizontal-center ps-md-5 pb-3">
                                        <div className="fc-white fs-dev-2-half fw-600">{t('title.questions')}</div>
                                    </Col>
                                    <Col md="3" className="ps-md-5">
                                        <a href="/#/contact">
                                            <button className="br-2 px-4 border-0 border-main bg-white color-main"> 
                                                <span className="gradient-text fs-dev-1 fw-400">
                                                    {t('button.contact')}
                                                </span>
                                            </button>                                            
                                        </a>
                                    </Col>
                                </Row>
                            </Container>
                            {/* <div id="question" className="d-md-flex">
                                
                            </div> */}
                        </section>

                        {/* Section Partner */}
                        <section className="px-5 pt-5">
                            <div id="partner" className="fs-dev-md-3 fs-dev-3 fw-600 title-color pb-3">{t('title.clients')}</div>
                            <div className="pb-5 fs-dev-1 fw-400">{t('title.sub-clients')}</div>
                            {/* <Row>
                                <Col sm>                                    
                                    <img className="w-100" src={PicCoor1} alt="" />
                                </Col>
                                <Col sm>
                                    <img className="w-100" src={PicCoor2} alt="" />
                                </Col>
                                <Col sm>
                                    <img className="w-100" src={PicCoor3} alt="" />
                                </Col>
                                <Col sm>
                                    <img className="w-100" src={PicCoor4} alt="" />
                                </Col>
                                <Col sm>
                                    <img className="w-100" src={PicCoor5} alt="" />
                                </Col>
                            </Row>                             */}
                            {/* <Row className="justify-content-md-center">
                                <Col md="auto">                                    
                                    <img className="w-100" src={PicCoor1} alt="" />
                                </Col>
                                <Col md="auto">
                                    <img className="w-100" src={PicCoor2} alt="" />
                                </Col>
                            </Row>                             */}
                            <Row>
                                <Col sm>                                    
                                    <img className="w-100 image-div" src={PicCoor1} alt="" loading="lazy" width="880" height="360"/>
                                </Col>
                                <Col sm>
                                    <img className="w-100 image-div" src={PicCoor2} alt="" loading="lazy" width="880" height="360"/>
                                </Col>
                                <Col sm>
                                    <img className="w-100 image-div" src={PicCoor3} alt="" loading="lazy" width="880" height="360"/>
                                </Col>
                            </Row>                            
                            <Row>
                                <Col sm>                                    
                                    <img className="w-100 image-div" src={PicCoor4} alt="" loading="lazy" width="880" height="360"/>
                                </Col>
                                <Col sm>
                                    <img className="w-100 image-div" src={PicCoor5} alt="" loading="lazy" width="880" height="360"/>
                                </Col>
                                <Col sm>
                                    <img className="w-100 image-div" src={PicCoor6} alt="" loading="lazy" width="880" height="360"/>
                                </Col>
                            </Row>                            
                        </section>


                        {/* Section About */}
                        {/* <section className="px-5">
                            <div id="about-us" className="d-md-flex w-100">
                                <Col md="6" className="d-md-flex d-none align-items-center px-3">
                                {
                                    (i18next.language ==='en')?
                                    <img className="w-100" src={require('../../media/home/about.webp').default} alt="" />
                                    :
                                    (i18next.language ==='id')?
                                    <img className="w-100" src={require('../../media/home/about-id.webp').default} alt="" />
                                    :
                                    null
                                }
                                </Col>
                                <Col md="6" className="ps-md-5">
                                    <h2 className="fw-600 fs-dev-md-4 fs-dev-3 text-dev-md-left pb-3 title-color mb-0">{t('title.about')}</h2>
                                    <p className="text-dev-md-left">{t('home.about')}</p>
                                </Col>
                            </div>
                        </section> */}

                        {/* Section Vision and Mission */}
                        {/* <section className="px-5">
                            <div id="vision-and-mission" className="d-md-flex w-100">
                                <Col md="6" className="pe-md-2 position-relative d-flex justify-content-center">
                                    <div className="bg-main-shadow text-dev-left p-5 br-2 h-100 z-2">
                                        <h2 className="color-main pb-3 fs-dev-md-4 fs-dev-3 fw-600 mb-0">{t('title.vision')}</h2>
                                        <div className="color-main">{t('home.vision')}</div>
                                    </div>
                                    <div className="box-shadow w-75 h-100 position-absolute top-0 z-1"></div>
                                </Col>
                                <Col md="6" className="mt-md-0 mt-4 ps-md-2 position-relative d-flex justify-content-center">
                                    <div className="bg-main-shadow text-dev-left p-5 br-2 h-100 z-2">
                                        <h2 className="color-main pb-3 fs-dev-md-4 fs-dev-3 fw-600 mb-0">{t('title.mission')}</h2>
                                        <div className="color-main">{t('home.mission')}</div>
                                    </div>
                                    <div className="box-shadow w-75 h-100 position-absolute top-0 z-1"></div>
                                </Col>
                            </div>
                        </section> */}

                            
                        {/* Section Benefit */}
                        {/* <section className="px-5">
                            <h2 id="benefit" className="fs-dev-md-4 fs-dev-3 fw-600 pb-3 title-color mb-0">{t('title.benefit')}</h2>
                            <div className="pt-md-4 pt-3 d-md-flex">
                                <Col md="4" className="px-3 py-md-0 py-2">
                                    <div className="fw-500 tab tab-benefit transition cursor-pointer py-3 br-1 active" onClick={()=> this.changeTab(0,"benefit")}>{t('home.benefit-customer.info')}</div>
                                </Col>
                                <Col md="4" className="px-3 py-md-0 py-2">
                                    <div className="fw-500 tab tab-benefit transition cursor-pointer py-3 br-1" onClick={()=> this.changeTab(1,"benefit")}>{t('home.benefit-partner.info')}</div>
                                </Col>
                                <Col md="4" className="px-3 py-md-0 py-2">
                                    <div className="fw-500 tab tab-benefit transition cursor-pointer py-3 br-1" onClick={()=> this.changeTab(2,"benefit")}>{t('home.benefit-society.info')}</div>
                                </Col>
                            </div>
                            
                            <Slider ref={slider => (this.benefitSlider = slider)} {...settings} className="py-md-5 py-3">
                                <div className="d-md-flex w-100">
                                    <Col md="6" className="px-3">
                                        {
                                            (i18next.language ==='en')?
                                            <img className="w-100" src={require('../../media/home/benefit-cust.webp').default} alt="" />
                                            :
                                            (i18next.language ==='id')?
                                            <img className="w-100" src={require('../../media/home/benefit-cust-id.webp').default} alt="" />
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col md="6" className="d-flex align-items-center">
                                        <ul className="mb-0">
                                            {t('home.benefit-customer.detail', {joinArrays : '\n'}).split('\n').map((line, index) => 
                                               <li className="text-dev-left py-2" key={index}>{line}</li>
                                            )}
                                        </ul>
                                    </Col>
                                </div>
                                
                                <div className="d-md-flex w-100">
                                    <Col md="6" className="px-3">
                                        <img className="w-100" src={PicBenefit2} alt="" />
                                    </Col>
                                    <Col md="6" className="d-flex align-items-center">
                                        <ul className="mb-0">
                                            {t('home.benefit-partner.detail', {joinArrays : '\n'}).split('\n').map((line, index) => 
                                                <li className="text-dev-left py-2" key={index}>{line}</li>
                                            )}
                                        </ul>
                                    </Col>
                                </div>
                                
                                <div className="d-md-flex w-100">
                                    <Col md="6" className="px-3">
                                        {
                                            (i18next.language ==='en')?
                                            <img className="w-100" src={require('../../media/home/benefit-society.webp').default} alt="" />
                                            :
                                            (i18next.language ==='id')?
                                            <img className="w-100" src={require('../../media/home/benefit-society-id.webp').default} alt="" />
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col md="6" className="d-flex align-items-center">
                                        <ul className="mb-0">
                                            {t('home.benefit-society.detail', {joinArrays : '\n'}).split('\n').map((line, index) => 
                                                <li className="text-dev-left py-2" key={index}>{line}</li>
                                            )}
                                        </ul>
                                    </Col>
                                </div>
                            </Slider>
                        </section> */}

                        {/* Section Partner */}
                        {/* <section className="px-5 pt-5">
                            <h2 id="partner" className="fs-dev-md-4 fs-dev-3 fw-600 pb-3 title-color">{t('title.coorperating')}</h2>
                            <div className="pt-4 d-flex flex-md-nowrap flex-wrap justify-content-center pb-md-4 pb-3">
                                {t('home.partners', {joinArrays : '\n'}).split('\n').map((line,index) =>(
                                    line === 'E-commerce'?
                                    <div className="col-partner px-3 py-2 text-md-nowrap"><div className={index === 0 ? "cursor-pointer py-2 d-flex justify-content-center align-items-center w-100 h-100 tab tab-partner active px-3 br-1" : "cursor-pointer py-2 d-flex justify-content-center align-items-center w-100 h-100 tab tab-partner px-3 br-1"} onClick={() => this.changeTab(index,"cooporating")} key={index}>{line}</div></div>
                                    :
                                    <div className="col-partner px-3 py-2"><div className={index === 0 ? "cursor-pointer py-2 d-flex justify-content-center align-items-center w-100 h-100 tab tab-partner active px-3 br-1" : "cursor-pointer py-2 d-flex justify-content-center align-items-center w-100 h-100 tab tab-partner px-3 br-1"} onClick={() => this.changeTab(index,"cooporating")} key={index}>{line}</div></div>)
                            
                                )}
                            </div>
                            <Slider className="pt-md-5 pt-3" ref={slider => (this.coorporatingSlider = slider)} {...settings}>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor1} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor2} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor3} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor4} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor5} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor6} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor7} alt="" />
                                </div>
                                <div className="w-100 px-md-5 px-3">
                                    <img className="w-100" src={PicCoor8} alt="" />
                                </div>
                            </Slider>
                        </section> */}

                    </Row>
                </Container>

            </>
        );
    }           
}

export default withTranslation()(Home);