import React from "react";
// import {Navbar, Container, Nav,NavDropdown, NavLink}  from 'react-bootstrap';
import {Navbar, Container, Nav}  from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
// import ReactCountryFlag from "react-country-flag";
import {withRouter} from 'react-router-dom';

import '../css/component/header.css';

// import { NavHashLink as Link  } from 'react-router-hash-link';
// import i18next from "i18next";

class Header extends React.Component{ 
    constructor(){
        super();

        // let urlPageBlue = ["register","profile","login","profile-2"];
        let urlPageBlue = ["profile","login","profile-2"];
        let pageCustom = false;

        urlPageBlue.forEach((item)=>{
            if(document.URL.endsWith(item)){
                pageCustom = true;
            }
        })

        let logo = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || pageCustom ? "logo.webp":"logo.webp";
        let color = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || pageCustom ? "black":"black";
            
        if(window.outerWidth < 993){
            logo = "logo.webp";
            color = "black";
        }
        
        let backgroundcolor = pageCustom ? "#426DB5": window.pageYOffset !==  0 || window.outerWidth < 992 ? "white" : "transparent";
            
        // let backgroundcolor = window.pageYOffset !=  0 || window.outerWidth < 992 ? "white" : "transparent";
        const boxShadow = window.pageYOffset !== 0 && window.outerWidth < 992 ? "0px 0px 15px 0 rgb(0 0 0 / 29%)" : "none" ;

        // backgroundcolor = pageCustom ?"#426DB5": backgroundcolor;

        this.state = 
        {
            token: null,
            logoUrl: logo,
            navBackground: backgroundcolor,
            boxShadow: boxShadow,
            colorText: color
        };
    }

    login(){
        // this.setState({token: "Vincent"});
    }

    setLogin(){
        let collapse = document.getElementsByClassName("dropdown-toggle");

        for(let i=0;i<collapse.length;i++){
            collapse[i].style.color = this.state.colorText;
        }
    }

    logout(){
        this.props.logout();
    }

    componentDidMount() {

        // let urlPageBlue = ["register","profile","login","profile-2"];
        let urlPageBlue = ["profile","login","profile-2"];
        let pageCustom = false;

        urlPageBlue.forEach((item)=>{
            if(document.URL.endsWith(item)){
                pageCustom = true;
            }
        })

        let colorStart = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || pageCustom ? "black":"black";
        
        if(window.outerWidth < 993 && !pageCustom){
            colorStart = "black";
        }

        let collapse = document.getElementsByClassName("dropdown-toggle");
        for(let i=0;i<collapse.length;i++){
            collapse[i].style.color = colorStart;
        }

        this.unlisten = this.props.history.listen((location, action) => {
            let checkPage = false;
            // console.log(document.URL);
            urlPageBlue.forEach((item)=>{
                if(document.URL.endsWith(item)){
                    checkPage = true;
                }
            })

            let logo = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || (checkPage)? "logo.webp":"logo.webp";
            let color = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || (checkPage)? "black":"black";
            
            if(window.outerWidth < 993 && (!checkPage)){
                logo = "logo.webp";
                color = "black";
            }

            let backgroundcolor = checkPage ? "#426DB5": window.pageYOffset !==  0 || window.outerWidth < 992 ? "white" : "transparent";
            
            // let backgroundcolor = window.pageYOffset !=  0 || window.outerWidth < 992 ? "white" : "transparent";
            const boxShadow = window.pageYOffset !== 0 && window.outerWidth < 992 ? "0px 0px 15px 0 rgb(0 0 0 / 29%)" : "none" ;
    
            // backgroundcolor = (pageCustom)?"#426DB5": backgroundcolor;

            let collapse = document.getElementsByClassName("dropdown-toggle");
            for(let i=0;i<collapse.length;i++){
                collapse[i].style.color = color;
            }

            this.setState({ logoUrl: logo });
            this.setState({ colorText: color });
            this.setState({ navBackground: backgroundcolor });
            this.setState({ boxShadow: boxShadow });
        });
        
        document.addEventListener("scroll", () => {
            let checkPage = false;
            // console.log(document.URL);
            urlPageBlue.forEach((item)=>{
                if(document.URL.endsWith(item)){
                    checkPage = true;
                }
            })

            let logo = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || (checkPage)? "logo.webp":"logo.webp";
            let color = (!document.URL.endsWith("home") && window.pageYOffset ===  0) || (checkPage)? "black":"black";
            
            if(window.outerWidth < 993 && (!checkPage)){
                logo = "logo.webp";
                color = "black";
            }

            let backgroundcolor = checkPage ? "#426DB5": window.pageYOffset !==  0 || window.outerWidth < 992 ? "white" : "transparent";
            
            // console.log(backgroundcolor);

            // let backgroundcolor = window.pageYOffset !=  0 || window.outerWidth < 992 ? "white" : "transparent";
            const boxShadow = window.pageYOffset !== 0 && window.outerWidth < 992 ? "0px 0px 15px 0 rgb(0 0 0 / 29%)" : "none" ;

            // backgroundcolor = (pageCustom)?"#426DB5": backgroundcolor;

            let collapse = document.getElementsByClassName("dropdown-toggle");
            for(let i=0;i<collapse.length;i++){
                collapse[i].style.color = color;
            }

            this.setState({ logoUrl: logo });
            this.setState({ colorText: color });
            this.setState({ navBackground: backgroundcolor });
            this.setState({ boxShadow: boxShadow });
        });
    }

    render() {
        // let token = this.props.token;
        // const { t,i18n } = this.props;
        const { t, location } = this.props;

        // console.log("location : "+JSON.stringify(location));
        // console.log("location.hash : "+location.hash);
        let pathName = "/"+location.hash;

        const hashParts = pathName.split('#');

        if(hashParts.length>2){
            pathName = "/#"+hashParts[1];
        }
        // console.log("pathName : "+pathName);

        // const changeLanguage = (language) => {
        //     i18n.changeLanguage(language);
        // };
        // eslint-disable-next-line
        return (
            <>
                <Navbar id="header" collapseOnSelect fixed="top" className="px-md-5 ph-1 transition" expand="lg" style={{backgroundColor: `${this.state.navBackground}`,boxShadow: `${this.state.boxShadow}`}}>
                    <Container className="">
                        <Navbar.Brand  href="/#/">
                            {/* <img className="logo-header" src={require('../media/' + `${this.state.logoUrl}`).default} alt="" /> */}
                            <img className="logo-header" src={require(`../media/${this.state.logoUrl}`).default} alt="nilaiq logo" width="100%" height="86"/>
                        </Navbar.Brand>   
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto" defaultActiveKey="/#/home" activeKey={pathName}>
                                <Nav.Link className="my-auto px-3" activeclassname="fw-500 active" style={{color: `${this.state.colorText}`}} href="/#/home" >{t('menu.home')}</Nav.Link>
                                <Nav.Link className="my-auto px-3" activeclassname="fw-500" style={{color: `${this.state.colorText}`}} href="/#/products" >{t('menu.products')}</Nav.Link>
                                <Nav.Link className="my-auto px-3" activeclassname="fw-500" style={{color: `${this.state.colorText}`}} href="/#/news">{t('menu.news')}</Nav.Link>
                                {/* <NavLink className="my-auto px-3" activeclassname="fw-500" style={{color: `${this.state.colorText}`}} href="/#/career">{t('menu.career')}</NavLink> */}
                                <Nav.Link className="my-auto px-3" activeclassname="fw-500" style={{color: `${this.state.colorText}`}} href="/#/contact">
                                    {t('menu.contact')}
                                    {/* <button type="button" className="my-auto button-header br-2 py-2 px-3 border-0 border-main bg-white color-main justify-content-center align-items-center"> 
                                        <span className="gradient-text">
                                            {t('menu.contact')}
                                        </span>
                                    </button> */}
                                </Nav.Link>
                                
                                {/* <NavLink href="/#/login" className="btn btn-danger text-white fw-600">Login</NavLink> */}
                                {/* <div className={window.scrollY < 100 ? "py-1 px-2" : "py-1 px-2 br-1 btn-language"}> */}
                                {/* <div className="vr"></div>
                                <div className="py-1 px-2 d-flex justify-content-center">
                                    {
                                        (i18next.language ==='en')?
                                        <NavLink className="d-flex align-items-center" onClick={() => changeLanguage('id')}>
                                            <ReactCountryFlag countryCode="US" svg />
                                            <div className="ps-2" style={{color: `${this.state.colorText}`}}>EN</div>
                                        </NavLink>
                                        :
                                        (i18next.language ==='id')?
                                        <NavLink className="d-flex align-items-center" onClick={() => changeLanguage('en')}>
                                            <ReactCountryFlag countryCode="ID" svg />
                                            <div className="ps-2" style={{color: `${this.state.colorText}`}}>ID</div>
                                        </NavLink>
                                        :
                                        <div></div>
                                    }
                                </div> */}
                                {/* <div className="py-1 px-2 d-flex justify-content-center align-items-center text-white">
                                    {
                                    this.props.token == null?
                                    <NavLink className="my-auto px-3" activeClassName="fw-600" style={{color: `${this.state.colorText}`}} href="/#/login">Login</NavLink>
                                
                                    // <div style={{color: `${this.state.colorText}`}} onClick={() => this.login()}>Login</div>
                                    :
                                    <NavDropdown className="nav-collapse" title={this.props.token} style={{color: `${this.state.colorText}` }}>
                                        <NavDropdown.Item className="my-auto">Profile</NavDropdown.Item>
                                        <NavDropdown.Item className="my-auto" onClick={() => this.logout()}>Log Out</NavDropdown.Item>
                                    
                                        {this.setLogin()}
                                    </NavDropdown>
                                    }
                                </div> */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        );
    }    
}

export default withRouter(withTranslation()(Header));