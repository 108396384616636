import React from "react";
import {Container,Row,Nav}  from 'react-bootstrap';

class Missing extends React.Component {
    componentDidMount(){
        document.title = "NilaiQu | 404";
    }

    render(){
        return(
            <>
                <section className="pagebanner">
                    <Container fluid>
                        <Row>
                            <div className="col-lg-12">
                                <div className="bannerTitle text-left">
                                    <h2>404_Error</h2>
                                    <p>We are here to help you when you need your financial support, then we are help you.</p>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
                
                <section className="commonSection page_4040">
                    <Container fluid>
                        <Row>
                            <div className="col-lg-6 offset-lg-3">
                                <div className="content_404 text-center">
                                    <h1>404</h1>
                                    <h4>Opps! This page is not found.</h4>
                                    <p>
                                        Simply import the sample files we ship with the theme
                                        and the core structur for your site is already mind that
                                        you don’t use the demo content.
                                    </p>
                                    <Nav.Link className="common_btn" href="/">Back to home</Nav.Link>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default Missing;