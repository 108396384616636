import React from "react";
// import React, { useState, useRef } from "react";
import {Container,Row,Form, Col, Button}  from 'react-bootstrap';
// import PicContact from '../../media/contact/contact.png';
import { withTranslation } from 'react-i18next';
import i18next from "i18next";

import axios from 'axios';

import PopMessage from '../../component/pop-message';

import Recaptcha from "react-recaptcha";

class Contact extends React.Component {

    defaultState = {
        resultForm: null,
        verifyInput:false
    }

    constructor(props) {
        super(props);
        window.scrollTo(0,0);

        this.verify = this.verify.bind(this);

        this.state = this.defaultState;
        
    }

    verify(response){
        if(response){
            this.setState({
                verifyInput : true
            })
        }
    }
    
    componentDidMount(){
        document.title = "nilaiQ | Contact";
    }
  
    async submitContact(event){
        if(this.state.verifyInput){
            const form = event.currentTarget;
            let data = new FormData(event.target);

            if (form.checkValidity() === false) {
                // console.log("checkValidity: "+form.checkValidity())
                event.preventDefault();
                event.stopPropagation();
                // this.setState({validated:true});
            }else{
    
                // let dataJSON = new Object();
                let dataJSON = {};
                dataJSON.firstName = data.get("name");
                dataJSON.lastName = data.get("name");
                dataJSON.email = data.get("email");
                dataJSON.phone = data.get("phone");
                dataJSON.content = data.get("message");
                // dataJSON.language = i18next.language;
        
                document.getElementById("header").classList.add("z-4");
                
                // let url = "https://h5.nilaiq.com/credit_score/api/email";
                let url = "https://h5.nilaiq.com/api/email";
        
                axios.post(url, JSON.stringify(dataJSON), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res =>{
                    let result = res.data;
                    // console.log("Submit result: "+result)
    
                    switch(result.data){
                        case 1 :
                            this.showPopUp();
                            break;
                        default:
                            // console.log("failed to send message")
                            this.setState({
                                resultForm : {
                                    title: "Not Sent",
                                    message: "Failed to send message, please try again later. Thank you"
                                }
                            });
                            break;
                    }
                

                    event.stopPropagation();
                    form.reset();
                    // console.log('form reset');
                    // form.reset();
                    // form.restart();

                    // this.setState({validated:true});

                }).catch((error) => {
                    if(error.response){
                        // console.log('response: ', error.response.data);
                        this.setState({
                            resultForm : {
                                title: "Info",
                                message: error.response.data.message+" Thank you."
                            }
                        });    
                    }
                    // event.preventDefault();
                    event.stopPropagation();
                });                
            }
            // console.log("End submit")
            event.preventDefault();
            this.setState({validated:true});
   
            
        }else{
            alert("Please verify captcha")
        }
        
    }


    showPopUp = () => {
        if(i18next.language === "id"){
            this.setState({
                resultForm : {
                    title: "Terkirim",
                    message: "Terima kasih telah menghubungi kami, kami akan segera merespon"
                }
            })
        }
        else if (i18next.language === "en"){
            this.setState({
                resultForm : {
                    title: "Sent",
                    message: "Thank you for contacting us, we will respond soon"
                }
            })
        }
    }

    closePop = () => {
        document.getElementById("header").classList.remove("z-4");
        this.setState({resultForm: null}); 
        this.setState({validated:false});        
    }

    render(){
        // const { t,i18n } = this.props;
        const { t } = this.props;
        // const [validated, setValidated] = React.useState(false);
        let popHtml = <PopMessage items= {this.state.resultForm} setChanged={this.closePop}></PopMessage>;
        // const emailRegex="([A-Za-z0-9][._]?)+[A-Za-z0-9]@[A-Za-z0-9]+(\.?[A-Za-z0-9]){2}\.(com?|net|org)+(\.[A-Za-z0-9]{2,4})?";

        return(
            <>
                {
                  this.state.resultForm?
                  popHtml
                  :
                  <div></div>
                }

                <Container className="position-relative">
                    <section className="px-5">
                        <div className="bg-img-color">
                            <div className="bg-img-banner">
                                <Container className="center-content">
                                    <div className="color-black fs-dev-3 fw-600">{t('title.contact')}</div>
                                    <div className="fs-dev-1 pt-3">{t('contact.subTitle')}</div>
                                </Container>
                            </div>
                        </div>                            
                    </section>
                </Container>

                {/* Section Form */}
                <section className="shadow pt-4 px-5 bg-white br-1 overlap-content overlap-content-md">
                    <Form id="form-contact" noValidate className="flex-column w-100" validated={this.state.validated} onSubmit={this.submitContact.bind(this)}>
                        <Form.Group className="mb-3 text-dev-md-left fs-dev-1 color-black" controlId="validationCustom01">
                            <Form.Label>{t('contact.name')}</Form.Label>
                            <Form.Control required name="name" type="text" />
                            <Form.Control.Feedback type="invalid">
                                {t('contact.name-invalid-feedback')}
                            </Form.Control.Feedback>                            
                        </Form.Group>
                        <Form.Group className="mb-3 text-dev-md-left fs-dev-1" controlId="formBasicEmail">
                            <Form.Label>{t('contact.email')}</Form.Label>
                            <Form.Control required name="email" type="email" placeholder="" pattern="([A-Za-z0-9][._]?)+[A-Za-z0-9]@[A-Za-z0-9]+(\.?[A-Za-z0-9]){2}\.(com?|net|org)+(\.[A-Za-z0-9]{2,4})?"/>
                            {/* <Form.Control required name="email" type="email" placeholder="" pattern={emailRegex}/> */}
                            <Form.Control.Feedback type="invalid">
                                {t('contact.email-invalid-feedback')}
                            </Form.Control.Feedback>                            
                        </Form.Group>
                        <Form.Group className="mb-3 text-dev-md-left fs-dev-1">
                            <Form.Label>{t('contact.phone')}</Form.Label>
                            <Form.Control name="phone" type="text" />
                        </Form.Group>
                        <Form.Group className="mb-3 text-dev-md-left fs-dev-1" controlId="validationCustom02">
                            <Form.Label>{t('contact.message')}</Form.Label>
                            <Form.Control required name="message" as="textarea" rows={3} />
                            <Form.Control.Feedback type="invalid">
                                {t('contact.message-invalid-feedback')}
                            </Form.Control.Feedback>                            

                        </Form.Group>

                        {/* <Form.Group className="mb-3 text-dev-md-left fs-dev-1" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mb-3 text-dev-md-left" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group> */}
                        <Row className="d-flex justify-content-end">
                            <Col md="auto pt-2 captcha-style">
                                <Recaptcha
                                    sitekey="6Lck9z0lAAAAACgnIt_RH2L1VQeHEgtT_hfT5WA5"
                                    render="explicit"
                                    verifyCallback={this.verify}
                                />
                            </Col>
                            <Col md="auto pt-2">
                                <Button className="btn-main bg-button-gradient border-0 br-2 px-5 fs-dev-1" type="submit">
                                    {t('contact.button')}
                                </Button>
                            </Col>

                        </Row>
                                    
                    </Form>                    
                </section>                

                {/* <section className="bg-img-color">
                    <Container className="px-md-5 px-3 bg-img-banner">
                        <Row>
                            <div className="pt-md-0">
                                <div className="fs-dev-3 fw-700 pb-md-4 fw-700">{t('title.contact')}</div>
                            </div>
                        </Row>
                    </Container>
                </section> */}


            </>
        );
    }
}

export default withTranslation()(Contact);