import React, { Suspense } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import './css/public/developer.css';
import './css/public/preset.css';
import './css/public/theme.css';
import './css/public/payloan-icon.css';

import "./i18n";

ReactDOM.render(
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <App />
      </Suspense>
    </BrowserRouter>
    ,document.getElementById('root')
);