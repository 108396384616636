import React from 'react';
// import Puzzle from 'puzzle-react';
// import Pic from '../media/home/key.png';

import SlideImage from './verify/slide-Image';

class VerifyImage extends React.Component{
    closeVerify(result){
        if(result === "success"){
            setTimeout(()=>{
                this.props.close();
            }, 2000);
        }
    }

    render(){
        return (
        <>
            <div className='br-1 bg-white py-3'>
                <h2 className='fw-700 fs-dev-2 text-dark mb-0 pb-3'>Drag to Verify</h2>
                <div className='pb-3'>
                    <SlideImage
                        img="https://images.unsplash.com/photo-1462524500090-89443873e2b4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
                        complete={(res) => {this.closeVerify(res)}}
                        successText="Success Captcha"
                        failText="Try Again"
                    />
                </div>
            </div>
        </>
        )
    }
}

export default VerifyImage;