import React from "react";
import Logo from "../media/logo-white.webp";
import AftechLogo from "../media/aftech.webp";
import KominfoLogo from "../media/kominfo-white.webp";
// import {Container,Col,Row,Form,Button,Nav}  from 'react-bootstrap';
import {Container,Col,Row,Nav}  from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import '../css/component/footer.css';

class Footer extends React.Component{ 
    goUp(){
        window.scrollTo(0,0);
    }

    render() {
        // const { t,i18n } = this.props;
        const { t } = this.props;
        
        return (
            <>
                <div id="footer" className="bg-footer">
                    <Container className="ph-3per4 position-relative">
                        {/* <div className="position-absolute top-0 w-100 d-flex justify-content-center">
                            <div className="w-75 box-shadow-footer"></div>
                        </div> */}
                        <Row className="mx-0 ph-2 pb-3">
                            <div className="px-0 pb-4">
                                <div className="pb-3 d-md-flex w-100 md-content-center">
                                    <img className="img-logo" src={Logo} alt="" width="144" height="64" />
                                </div>

                                <div className="d-md-flex w-100">
                                    <Col md={4} className="py-lg-0 py-3 text-dev-left">
                                        <div className="fs-min-1 hover-fw-600 hover-main py-2 pb-3 md-content-center">PT. Kepercayaan Informasi Terintegrasi</div>
                                        <div className="fs-min-2 md-content-center">{t('footer.address')}:</div>
                                        <div className="fs-min-2 md-content-center">Sinarmas Land Tower 3 | 11th Floor</div>
                                        <div className="fs-min-2 md-content-center">Jl. M.H. Thamrin No. 51 Kav.22</div>
                                        <div className="fs-min-2 md-content-center">Gondangdia, Menteng</div>
                                        <div className="fs-min-2 md-content-center">Jakarta 10350</div>
                                        <div className="fs-min-2 md-content-center">Indonesia</div>
                                    </Col>
                                    <Col md={3} className="py-lg-0 py-3 px-md-5 text-dev-md-left fs-min-1">
                                        <div className="d-flex py-2 md-content-center">
                                            <Nav.Link className="p-0 m-0 hover-main hover-fw-600 transition" href="/#/home">{t('menu.home')}</Nav.Link>
                                        </div>
                                        <div className="d-flex py-2 md-content-center">
                                            <Nav.Link className="p-0 m-0 hover-main hover-fw-600 transition" href="/#/products">{t('menu.products')}</Nav.Link>
                                        </div>
                                        <div className="d-flex py-2 md-content-center">
                                            <Nav.Link className="p-0 m-0 hover-main hover-fw-600 transition" href="/#/news">{t('menu.news')}</Nav.Link>
                                        </div>
                                        <div className="d-flex py-2 md-content-center">
                                            <Nav.Link className="p-0 m-0 hover-main hover-fw-600 transition" href="/#/contact">{t('menu.contact')}</Nav.Link>
                                        </div>
                                        <div className="d-flex py-2 md-content-center">
                                            <Nav.Link className="p-0 m-0 hover-main hover-fw-600 transition" href="/#/privacy">{t('menu.privacy')}</Nav.Link>
                                        </div>
                                    </Col>
                                    {/* <Col md={3} className="py-lg-0 py-3 px-md-5 px-0">
                                        <div className="d-flex py-2">
                                            <Nav.Link className="p-0 m-0 text-black hover-main hover-fw-600 transition" href="">Terms of services</Nav.Link>
                                        </div>
                                        <div className="d-flex py-2">
                                            <Nav.Link className="p-0 m-0 text-black hover-main hover-fw-600 transition" href="">Privacy policy</Nav.Link>
                                        </div>
                                        <div className="d-flex py-2">
                                            <Nav.Link className="p-0 m-0 text-black hover-main hover-fw-600 transition" href="">Cookie policy</Nav.Link>
                                        </div>
                                    </Col> */}

                                    <Col md={3} className="px-md-4 text-dev-left pt-2">
                                        <Nav.Link className="px-1 hover-main hover-fw-600 fs-min-1 transition w-100 md-content-center" href="">{t('footer.kominfo-fintech-registered')}</Nav.Link>
                                        <Row className="pt-2 pb-4 px-1">
                                            <Col className="" xs={6}>
                                                <img className="" src={KominfoLogo} alt="" loading="lazy" width="79" height="86"/>
                                            </Col>
                                            <Col className="" xs={6}>                                    
                                                <img className="px-1" src={AftechLogo} alt="" loading="lazy" width="164" height="86"/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={3} className="px-md-4 text-dev-left pt-2">
                                        <Nav.Link className="hover-main hover-fw-600 fs-min-1 w-100 md-content-center" href="">{t('footer.ojk-registered')}</Nav.Link>
                                        <p className="hover-main hover-fw-600 fs-min-1 transition w-100 md-content-center">{t('footer.ojk-license')}</p>
                                    </Col>


                                    {/* <Col md={6} className="px-md-4 text-dev-left">

                                        <Row className="pt-2">
                                            <Col>
                                                <Nav.Link className="px-1 hover-main hover-fw-600 fs-min-1 transition w-100" href="">{t('footer.kominfo-fintech-registered')}</Nav.Link>
                                                <Row>
                                                    <Col xs={5}>
                                                        <img className="w-100" src={KominfoLogo} alt="" />
                                                    </Col>
                                                    <Col xs={7}>                                    
                                                        <img className="w-100 pt-cr-md-2" src={AftechLogo} alt="" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Nav.Link className="hover-main hover-fw-600 fs-min-1 w-100" href="">{t('footer.ojk-registered')}</Nav.Link>
                                                <p className="hover-main hover-fw-600 fs-min-1 transition w-100">{t('footer.ojk-license')}</p>
                                            </Col>
                                        </Row>
                                    </Col> */}
                                </div>
                            </div>
                            <hr></hr>
                        </Row>
                    </Container>
                    <div className="d-flex px-4 w-100 justify-content-center py-2">
                        <div className="d-flex align-items-center fs-min-1 fw-400">&copy; 2023 nilaiQ All Rights Reserved</div>
                    </div>
                </div>
                
            </>
        );
    }    
}

export default withTranslation()(Footer);