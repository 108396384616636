import React from "react";
import {Container,Row,Col}  from 'react-bootstrap';

import { withTranslation } from 'react-i18next';
import 'react-svg-radar-chart/build/css/index.css';
import '../../css/pages/products.css';

// import Player from '../../component/player';
import i18next from "i18next";

// import { browserHistory } from 'react-router';

// import { useHistory } from "react-router-dom";

class Products extends React.Component {

    constructor(props) {
        super(props);
        window.scrollTo(0,0);

    }
    
    componentDidMount(){
        document.title = "nilaiQ | Products";
    }


    render(){
    
        const { t } = this.props;
        return(
            <>
                {
                  <div></div>
                }


                <Container className="position-relative">
                    <section className="px-5">
                        <div className="bg-img-color">
                            <div className="bg-img-banner">
                                <Container className="center-content">
                                    <div className="pt-5 color-black fs-dev-3 fw-600 px-3 lh-sm">{t('products.headline')}</div>
                                    <div className="fs-min-1 pt-3 fw-400">{t('products.subHeadline')}</div>
                                </Container>
                            </div>
                        </div>                            
                    </section>
                </Container>

                <Container className="position-relative z-2">
                    <Row className="d-block">
                        {/* Section nilaiq */}
                        <section id="nilaiq" className="px-6">
                            <div className="fs-dev-md-3 fs-dev-3 fw-600 title-color lh-sm">{t('products.nilaiq.title')}</div>
                            <div className="d-md-flex text-dev-md-left pt-md-5 pt-3 pb-4 title-color">
                                <Col md="6">
                                    <div className="fs-dev-1-half pt-3 fw-600 pb-3">{t('products.nilaiq.subTitle')}</div>
                                    <ul className="mb-0">
                                        {t('products.nilaiq.detail', { returnObjects: true }).map((item, index) => (
                                            <li className="text-dev-md-left lh-md title-color px-4" key={index}>
                                                {/* {item.title} */}
                                                <p className="fs-dev-1 fc-gray lh-sm pb-2 pt-2 fw-400">{item}</p>
                                            </li>
                                        ))}                                        
                                    </ul>
                                </Col>
                                <Col md="6" className="px-4 pt-md-0 pt-3">
                                    {
                                        (i18next.language ==='en')?
                                        <img className="w-100" src={require('../../media/nilaiq-score.webp').default} alt="" />
                                        :
                                        (i18next.language ==='id')?
                                        <img className="w-100" src={require('../../media/nilaiq-score.webp').default} alt="" />
                                        :
                                        null
                                    }
                                </Col>
                            </div>
                        </section>

                        {/* Section bespoke */}
                        <section id="bespoke" className="px-6">
                            <div className="fs-dev-md-3 fs-dev-3 fw-600 title-color lh-sm">{t('products.bespoke.title')}</div>
                            <div className="d-md-flex text-dev-md-left pt-md-5 pt-3 pb-4 title-color">
                                <Col md="6">
                                    <div className="fs-dev-1-half pt-3 fw-600 pb-3">{t('products.bespoke.subTitle')}</div>
                                    <ul className="mb-0">
                                        {t('products.bespoke.detail', { returnObjects: true }).map((item, index) => (
                                            <li className="text-dev-md-left lh-md title-color px-4" key={index}>
                                                {/* {item.title} */}
                                                <p className="fs-dev-1 fc-gray lh-sm pb-2 pt-2 fw-400">{item}</p>
                                            </li>
                                        ))}                                        
                                    </ul>
                                </Col>
                                <Col md="6" className="px-4 pt-md-0 pt-3">
                                    {
                                        (i18next.language ==='en')?
                                        <img className="w-100" src={require('../../media/bespoke-score.webp').default} alt="" />
                                        :
                                        (i18next.language ==='id')?
                                        <img className="w-100" src={require('../../media/bespoke-score.webp').default} alt="" />
                                        :
                                        null
                                    }
                                </Col>
                            </div>
                        </section>

                        {/* Section Question */}
                        <section className="br-1">
                            <Container className="px-5 bg-color-gradient br-1 text-vertical-middle">
                                <Row className="w-100 m-0 pt-3">
                                    <Col md="9" className="text-dev-left text-horizontal-center ps-md-5 pb-3">
                                        <div className="fc-white fs-dev-2-half fw-600">{t('title.questions')}</div>
                                    </Col>
                                    <Col md="3" className="ps-md-5">
                                        <a href="/#/contact">
                                            <button className="br-2 px-4 pt-0 border-0 border-main bg-white color-main"> 
                                                <span className="gradient-text fs-dev-1 fw-400">
                                                    {t('button.contact')}
                                                </span>
                                            </button>                                            
                                        </a>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                    </Row>
                </Container>

            </>
        );
    }           
}

export default withTranslation()(Products);